import {extendObservable} from 'mobx';
import Debug from './../utils/Debug';
import BaseEvents from './../utils/BaseEvents';

const isSamsungGayAssBrowser = /samsungbrowser\/(7|8|9)/gi.test(navigator.userAgent);
const isGayAssLinkedinAppOnIphone = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);

/**
 * @class VideoStore
 */
class VideoStore extends BaseEvents {

    isSamsungGayAssBrowser = isSamsungGayAssBrowser;
    /**
     * @type {States}
     */
    status = VideoStore.States.PRE_VIDEO;

    /**
     * @type {VideoStatus}
     */
    playing = VideoStore.VideoStatus.NOT_PLAYING;

    /**
     *
     * @type {HTMLVideoElement|null}
     */
    video = null;

    settings = [];

    // validKeys = [' '];

    /**
     *
     * @type {boolean}
     */
    showingContinueButton = false;

    /**
     *
     * @type {{timeout: number, buttonAt: number, start: number, end: number}|boolean}
     */
    loopSettings = false;

    /**
     *
     * @type {boolean}
     */
    inputDown = false;

    /**
     *
     * @type {boolean}
     */
    isFullScreen = false;

    /**
     *
     * @type {number}
     */
    watchPhoneStartTime = 0;
    /**
     *
     * @param {object[]} settings
     * @param {string} settings[].name
     * @param {number} settings[].start
     * @param {number} settings[].end
     * @param {string} settings[].state
     * @param {object|boolean} settings[].loop
     * @param {number} [settings[].loop.buttonAt]
     */
    constructor(settings){
        super();
        this.settings        = settings;
        this.checkTimeUpdate = this.checkTimeUpdate.bind(this);
        this.setListeners();


        extendObservable(this, {
            status               : VideoStore.States.PRE_VIDEO,
            playing              : VideoStore.VideoStatus.NOT_PLAYING,
            loopSettings         : false,
            inputDown            : false,
            showingContinueButton: false,
            isFullScreen         : false,
            watchPhoneStartTime  : 0
        })
        /*
        *

extendObservable(VideoStore, {
    status               : observable,
    playing              : observable,
    loopSettings         : observable,
    inputDown            : observable,
    showingContinueButton: observable,
    onTouchStart         : action,
    onTouchEnd           : action,
    setStatus            : action,
    setLoopSettings      : action,
    setVideoStatus       : action,
    checkShowInputButton : action
});
*/
    }

    /**
     *
     * @param {string} category
     * @param {string} type
     * @param {string} label
     */
    track(category, type, label){
        Debug.log('videoStore | track()', category, type, label);
        if(window.ga){
            window.ga('send','event',category,type,label);
        }

    }

    setListeners(){
        // this.onInputTimeoutDone = this.onInputTimeoutDone.bind(this);
        // window.addEventListener('keydown', this.onKeyEvent.bind(this, 'down'));
        // window.addEventListener('keyup', this.onKeyEvent.bind(this, 'up'));
        // window.addEventListener('mousedown', this.onInputDown.bind(this));
        // window.addEventListener('mouseup', this.onInputUp.bind(this));
        window.addEventListener('touchstart', this.onTouchStart.bind(this));
        window.addEventListener('touchend', this.onTouchEnd.bind(this));

        window.addEventListener('click', this.onWindowClick.bind(this));
    }

    onTouchStart(){
        if(this.showingContinueButton){
            this.inputDown = true;
        }
    }

    onTouchEnd(){
        this.inputDown = false;
    }

    onWindowClick(){
        if(this.showingContinueButton && this.loopSettings){
            // trigger to continue!
            this.track('Continue','click', this.status.toString());
            const newTime = this.loopSettings.end;
            this.setLoopSettings(false);
            this.changeVideoTime(newTime);
        }
    }


    /**
     * @param {States} status
     */
    setStatus(status){
        //
        if(status !== this.status){
            Debug.log('VideoStore | changeState() | new status:', status);
            if(this.status === VideoStore.States.LOOP_ONE && status === VideoStore.States.WATCHING_PHONE){
                // set watch phone start time
                this.watchPhoneStartTime = Date.now();
            }

            this.trigger('set-status', this.status, status);
        }
        this.status = status;
    }

    /**
     *
     * @param {boolean|object} settings
     * @param {number} settings.start
     * @param {number} settings.end
     * @param {number} settings.buttonAt
     */
    setLoopSettings(settings){
        this.loopSettings = settings;
    }

    checkShowInputButton(){
        let show = false;
        if(!this.loopSettings){
            show = false;
        } else {
            show = !(this.showingContinueButton === false && this.loopSettings.buttonAt > this.video.currentTime);
        }
        if(show !== this.showingContinueButton){
            Debug.log('videoStore | checkShowInputButton() | new value:', show);
            this.showingContinueButton = show;
        }
    }

    /**
     * @param {VideoStatus} status
     */
    setVideoStatus(status){
        this.playing = status;
        if(status && !isGayAssLinkedinAppOnIphone) this.checkTimeUpdate();
    }

    /**
     * @param {HTMLVideoElement|null} videoElement
     */
    setVideo(videoElement){
        this.video = videoElement;
    }

    start(){
        if(this.video){
            this.setStatus(VideoStore.States.INTRO);
            this.trigger('video-start');
            this.video.play();
            this.setVideoStatus(VideoStore.VideoStatus.PLAYING);


            // lol these lines below are dirty, but doesnt matter for this project
            try{
                const fsElement = document.getElementById('root');
                if(fsElement.requestFullscreen) fsElement.requestFullscreen();
                else if(fsElement.requestFullScreen) fsElement.requestFullScreen();
                else if(fsElement.msRequestFullscreen) fsElement.msRequestFullscreen();
                else if(fsElement.mozRequestFullScreen) fsElement.mozRequestFullScreen();
                else if(fsElement.webkitRequestFullscreen) fsElement.webkitRequestFullscreen();

                this.isFullScreen = true;
            } catch(e){
                Debug.warn('fullscreen error?', e.message);
            }
        }
    }

    pause(){
        if(this.video) this.video.pause();
    }

    closeFullScreen(){

        if(document.body.requestFullscreen){
            if(document.fullscreenElement) document.exitFullscreen();
        } else if(document.body.requestFullScreen){
            if(document.fullScreenElement) document.cancelFullScreen();
        } else if(document.body.msRequestFullscreen){
            if(document.msFullscreenElement) document.msExitFullscreen();
        } else if(document.body.mozRequestFullScreen){
            if(document.mozFullScreenElement) document.mozCancelFullScreen();
        } else if(document.body.webkitRequestFullscreen){
            if(document.webkitFullscreenElement) document.webkitCancelFullScreen();
        }

        this.isFullScreen = false;
    }

    checkTimeUpdate(){
        if(this.video && !this.video.ended){
            const {currentTime, duration} = this.video;


            if(this.loopSettings && currentTime >= this.loopSettings.end && !isSamsungGayAssBrowser){
                this.changeVideoTime(this.loopSettings.start);
                // need a .1 offset just to be sure the next iteration can't change status by accident
            } else {
                let newStatus = VideoStore.States.PRE_VIDEO;
                let newInput  = false;

                this.settings.forEach((scene, i) => {
                    if(scene.start <= currentTime){
                        newStatus = VideoStore.States[scene.state];
                        newInput  = scene.loop;


                        if(newInput){
                            // add loop start and end
                            newInput.start = scene.start;
                            newInput.end   = this.settings[i + 1]? this.settings[i + 1].start : duration;
                        }
                    }
                });

                if(newStatus !== this.status){
                    Debug.log('status update!', newStatus, newInput);

                    this.setLoopSettings(newInput);
                    this.setStatus(newStatus);
                }
            }

            this.checkShowInputButton();

            if(this.playing && !isGayAssLinkedinAppOnIphone) requestAnimationFrame(this.checkTimeUpdate);
        }
    }

    /**
     *
     * @param {number} newTime
     */
    changeVideoTime(newTime){
        if(this.video && !isSamsungGayAssBrowser) this.video.currentTime = newTime + .2;
    }


    /**
     *
     * @enum {boolean} VideoStatus
     * @constructor
     */
    static get VideoStatus(){
        return {
            PLAYING    : true,
            NOT_PLAYING: false
        }
    }

    /**
     * @enum {string} States
     * @readonly
     */
    static get States(){

        return {
            PRE_VIDEO     : 'pre-video',
            INTRO         : 'intro',
            LOOP_ONE      : 'interaction-loop-one',
            WATCHING_PHONE: 'watching-phone',
            LOOP_TWO      : 'interaction-loop-two',
            SUSPICIOUS    : 'suspecting-situation',
            LOOP_THREE    : 'interaction-loop-three',
            CARNAVAL      : 'carnaval',
            END_MESSAGE   : 'end-message',
            VIDEO_ENDED   : 'video-ended'
        };
    }
}

export default VideoStore;