import React, {Component} from 'react';

export default class Icon extends Component {
    render(){
        let icon = null;

        switch(this.props.icon){
            case 'mail':
                icon = (<svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.76 23.62">
                    <g data-name="Layer 2">
                        <g data-name="Layer 1">
                            <path className="cls-1"
                                  d="M28.62,0H3.14A3.16,3.16,0,0,0,.61,1.26L14.74,15A1.63,1.63,0,0,0,17,15l14-14A3.24,3.24,0,0,0,28.62,0Z"/>
                            <path className="cls-1"
                                  d="M22.43,11.89l9.29,9c0-.12,0-.29,0-.41V3.14a2.32,2.32,0,0,0,0-.53Z"/>
                            <path className="cls-1" d="M9.28,12,0,2.93V20.56Z"/>
                            <path className="cls-1"
                                  d="M31,22.48l-9.78-9.41-3.09,3.09a3.08,3.08,0,0,1-2.28.94,3.24,3.24,0,0,1-2.28-.94l-3.14-3.05L.57,22.27a3.12,3.12,0,0,0,2.57,1.35H28.58A3.14,3.14,0,0,0,31,22.48Z"/>
                        </g>
                    </g>
                </svg>);
                break;
            case 'facebook':
                icon = (<svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.65 30.2">
                    <g data-name="Layer 2">
                        <g data-name="Layer 1">
                            <path className="cls-1"
                                  d="M11,0h1.85L13,0l1.47.07c.4,0,.79.08,1.18.12V5H12.88a7,7,0,0,0-1,.07,1.73,1.73,0,0,0-1.58,1.45,6.85,6.85,0,0,0-.12,1.1c0,1.09,0,2.17,0,3.25v.17h5.31l-.71,5.37H10.16V30.2H4.62c0-.07,0-.14,0-.2V16.42H0V11.05H4.61v-.2c0-1.15,0-2.3,0-3.45a17.14,17.14,0,0,1,.1-1.76A6.41,6.41,0,0,1,5.61,3,6.15,6.15,0,0,1,10,.12C10.34.07,10.67,0,11,0Z"/>
                        </g>
                    </g>
                </svg>);
                break;
            case 'linkedin':
                icon = (<svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.64 27.37">
                    <g data-name="Layer 2">
                        <g data-name="Layer 1">
                            <path className="cls-1"
                                  d="M16.05,11.44c.36-.43.68-.84,1-1.22a5.52,5.52,0,0,1,3.4-1.67,7.76,7.76,0,0,1,3.76.39,6,6,0,0,1,3.26,2.82,10.13,10.13,0,0,1,1.12,5c0,3.47,0,6.94,0,10.42a1.28,1.28,0,0,1,0,.19H22.49v-9.7a7,7,0,0,0-.33-2.4,2.77,2.77,0,0,0-3-1.92,3.34,3.34,0,0,0-3,2.42,5.28,5.28,0,0,0-.15,1.49c0,3.26,0,6.52,0,9.78v.32H9.93V8.91h5.85c.22,0,.28.07.27.28,0,.67,0,1.34,0,2Z"/>
                            <path className="cls-1"
                                  d="M6.49,8.91V27.36H.65c-.22,0-.27-.07-.27-.28V9.18c0-.21,0-.28.27-.28H6.49Z"/>
                            <path className="cls-1"
                                  d="M3.43,6.39A3.27,3.27,0,0,1,.52,4.94,3.12,3.12,0,0,1,2.25.21,3.71,3.71,0,0,1,5.62.65a3,3,0,0,1,1.26,3,2.88,2.88,0,0,1-2,2.49A6.35,6.35,0,0,1,3.43,6.39Z"/>
                        </g>
                    </g>
                </svg>);
                break;
            case 'twitter':
                icon = (<svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.14 24.93">
                    <g data-name="Layer 2">
                        <g data-name="Layer 1">
                            <path className="cls-1"
                                  d="M30.38.65A6.6,6.6,0,0,1,27.71,4a13.64,13.64,0,0,0,3.43-.78,8.19,8.19,0,0,1-.73.92c-.72.7-1.47,1.37-2.22,2a.55.55,0,0,0-.21.45,18.33,18.33,0,0,1-4.55,12.26,16.71,16.71,0,0,1-10.64,5.81A18.09,18.09,0,0,1,1.05,22.57C.76,22.41.47,22.22.18,22L0,21.91a13.51,13.51,0,0,0,4.93-.32,12.78,12.78,0,0,0,4.44-2.15,6.39,6.39,0,0,1-3.64-1.33,6.26,6.26,0,0,1-2.18-3.17,6.62,6.62,0,0,0,2.8-.07,6.41,6.41,0,0,1-4-2.94,6.21,6.21,0,0,1-.89-3.42,6.68,6.68,0,0,0,2.82.8A6.29,6.29,0,0,1,1.6,4.69,6.07,6.07,0,0,1,2.48.86,18.12,18.12,0,0,0,15.41,7.7c0-.38-.09-.72-.1-1.07A6.22,6.22,0,0,1,19.73.32,6.12,6.12,0,0,1,26,1.79a.71.71,0,0,0,.75.22A13.59,13.59,0,0,0,30.07.8,3.09,3.09,0,0,1,30.38.65Z"/>
                        </g>
                    </g>
                </svg>);
                break;
            case 'whatsapp':
                icon = (<svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.44 25.44">
                    <g data-name="Layer 2">
                        <g data-name="Layer 1">
                            <path className="cls-1"
                                  d="M0,25.44C.43,24.19.84,23,1.25,21.75c.32-.93.63-1.85.94-2.78a.47.47,0,0,0,0-.33,11.75,11.75,0,0,1-1.43-3.7A12.11,12.11,0,0,1,4.34,3.42,11.74,11.74,0,0,1,11.73.05,12.23,12.23,0,0,1,23.15,5.22a11.21,11.21,0,0,1,2.27,6.24,12.09,12.09,0,0,1-5.77,11.4,12.06,12.06,0,0,1-8,1.86A12.37,12.37,0,0,1,7.1,23.34a.48.48,0,0,0-.37,0L.23,25.38Zm3.15-3.07.26-.08c1-.32,2.06-.62,3.07-1a1.16,1.16,0,0,1,1.32.17l0,0a10.4,10.4,0,0,0,9.61.32,10.18,10.18,0,0,0,4.49-4,10.09,10.09,0,0,0,1.43-6.94,10.13,10.13,0,0,0-3.73-6.53,10.16,10.16,0,0,0-5.27-2.29,10.58,10.58,0,0,0-10.1,4.5A10.24,10.24,0,0,0,2.59,10.7a10.39,10.39,0,0,0,0,3.43,10.1,10.1,0,0,0,1.74,4.2.36.36,0,0,1,0,.36c-.22.64-.44,1.28-.65,1.92S3.35,21.75,3.15,22.37Z"/>
                            <path className="cls-1"
                                  d="M9.06,6.74h.11c.5-.08.74.19.91.62.27.69.55,1.37.86,2a.86.86,0,0,1-.12,1c-.19.26-.41.51-.62.75a.45.45,0,0,0-.07.62A8.24,8.24,0,0,0,13.58,15l.76.34a.47.47,0,0,0,.65-.16c.29-.35.57-.7.85-1.06s.42-.38.81-.2L19,15.06a.47.47,0,0,1,.28.47,2.41,2.41,0,0,1-1.54,2.36,3,3,0,0,1-2.31.2c-.93-.32-1.88-.63-2.79-1a8.9,8.9,0,0,1-2.71-2,13.85,13.85,0,0,1-2.68-3.68,3.72,3.72,0,0,1-.19-3A3.46,3.46,0,0,1,8.11,7,1.26,1.26,0,0,1,9.06,6.74Z"/>
                        </g>
                    </g>
                </svg>);
                break;
            case 'play':
                icon = (<svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.13 40.57">
                    <g data-name="Layer 2">
                        <g data-name="Layer 1">
                            <polygon className="cls-1" points="35.13 20.28 0 0 0 40.57 35.13 20.28"/>
                        </g>
                    </g>
                </svg>);
                break;
            default:
                icon = null;
                break;
        }

        return icon;
    }
}