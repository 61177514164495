import React, {Component} from 'react';
import {observer, inject} from "mobx-react";

function _isTouchDevice(){
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}

export default inject('store')(observer(class LoopTwoOverlay extends Component {

    render(){

        if(this.props.store.isSamsungGayAssBrowser) return null;

        const message = _isTouchDevice()
            ? 'tap to put down phone'
            : 'click to put down phone';

        const className = this.props.store.showingContinueButton ? 'messages-overlay show' : 'messages-overlay';
        // if(inputDown) className += ' ' + loopSettings.className;

        return (<div className={className}>
            <div className="center">
                <button className="btn">{message}</button>
            </div>
        </div>)
    }
}));