import React, {Component} from 'react';
import image from './../../media/phone_landscape_white.gif';

export default class RotatePhone extends Component {
    constructor(props){
        super(props);

        this.mq = window.matchMedia('(orientation: portrait)');

        this.state = {
            portrait: this.mq.matches
        };

        this.onOrientationChange = this.onOrientationChange.bind(this);
        this.mq.addListener(this.onOrientationChange)
    }

    componentWillUnmount(){
        this.mq.removeListener(this.onOrientationChange)
    }

    onOrientationChange(){
        this.setState({portrait: this.mq.matches})
    }

    render(){
        if(!this.state.portrait || !this.props.playing) return null;


        return (<div className="rotate-phone">
            <p>Please rotate your phone</p>
            <img src={image} alt="rotate phone" />
        </div>);
    }
}