import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import Debug from './../utils/Debug';
import Icon from './Icon';

// whatsapp://send?text=The text to share!
// const ga = window.ga || function(){};

function _isTouchDevice(){
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}

export default inject('store')(observer(class EndScreen extends Component {
    constructor(props){
        super(props);

        this.URL = encodeURIComponent(this.props.meta.url);
        this.MESSAGE = encodeURI(this.props.meta.description);
        this.TITLE = encodeURI(this.props.meta.title);
        this.SPACE = encodeURI(' ');

        this.state = {
            show: false
        }
    }

    componentDidMount(){
        // do the animate in!
        setTimeout(() => {
            this.setState({show: true});
        }, 10);
    }

    closeFullScreen(){
        this.props.store.closeFullScreen();
    }

    onReWatchClick(e){
        e.preventDefault();
        Debug.log('EndScreen | onReWatchClick() ', 'kijk nog een keer!');
        this.props.store.track('Video', 'rewatch', 'main-video');
        // ga('send','event','Video','rewatch');
        if(this.props.reWatch) this.props.reWatch();
    }

    onShareClick(who){
        // ga('send','event','Share','click',who);
        this.props.store.track('Share','click', who);
    }

    render(){
        const t = "_blank";
        const r = "noopener noreferrer";
        const className = this.state.show ? "end-screen show" : "end-screen";

        const waBase = _isTouchDevice()? 'whatsapp://send?text=' : 'https://web.whatsapp.com/send?text=';
        const f = (document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement)
        const closeBtn = (f && this.props.store.isFullScreen)? (<span className="btn close-fullscreen" onClick={this.closeFullScreen.bind(this)}>&times;</span>) : null;

        return (<div className={className}>
            {closeBtn}
            <div className="center">
                <button className="btn" onClick={this.onReWatchClick.bind(this)}><span><Icon icon="play"/></span>watch
                    again
                </button>
                <div className="social-shares">
                    <a className="mail"
                       href={`mailto:?subject=${this.TITLE}&body=${this.MESSAGE}${this.SPACE}%0D%0A%0D%0A${this.URL}`}
                       onClick={()=>{this.onShareClick('mail')}}>
                        <Icon icon="mail"/>
                    </a>
                    <a className="facebook"
                       href={`https://www.facebook.com/sharer/sharer.php?u=${this.URL}`}
                       target={t}
                       rel={r}
                       onClick={()=>{this.onShareClick('facebook')}}>
                        <Icon icon="facebook"/>
                    </a>
                    <a className="linkedin"
                       href={`https://www.linkedin.com/shareArticle?mini=true&url=${this.URL}&summary=${this.MESSAGE}&title=${this.TITLE}`}
                       target={t}
                       rel={r}
                       onClick={()=>{this.onShareClick('linkedin')}}>
                        <Icon icon="linkedin"/>
                    </a>
                    <a className="twitter"
                       href={`https://twitter.com/home?status=${this.MESSAGE}${this.SPACE}${this.URL}`}
                       target={t}
                       onClick={()=>{this.onShareClick('twitter')}}>
                        <Icon icon="twitter"/>
                    </a>
                    <a className="whatsapp" data-action="share/whatsapp/share"
                       href={`${waBase}${this.TITLE}${this.SPACE}${this.URL}`}
                       target={t}
                       rel={r}
                       onClick={()=>{this.onShareClick('whatsapp')}}>
                        <Icon icon="whatsapp"/>
                    </a>
                </div>
                <p>
                    Since you are not offline yet, <a href="https://fxmedia.nl" onClick={()=>{this.props.store.track('Link','click','fxmedia.nl')}}>check out</a> the digital stuff we create.
                </p>
            </div>
        </div>);
    }
}));