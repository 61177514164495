/**
 *
 * @param {string} type
 * @param {*} item
 * @returns {boolean}
 * @private
 */
const _legal = function(type, item){
    if(item)  switch(type){
        case "string":
            return item.constructor === String;
        // break;
        case "function":
            return item.constructor === Function;
        // break;
        case "array":
            return item.constructor === Array;
        // break;
        default:
            break;
    }

    return false;
};
/**
 *
 * @param {object} list
 * @param {string} eventName
 * @param {function} callback
 * @returns {number}
 * @private
 */
const _exist = function(list, eventName, callback){
    if (list[eventName]) for(let i=0; i<list[eventName].length; i++){
        if (list[eventName][i] === callback) return i;
    }
    return -1;
};



class BaseEvents {
    constructor(){
        this._events = {
            on: {},
            once: {}
        };
    }

    /**
     * @param ctx
     * @param {string} where
     * @param {string} eventName
     * @param {function} callback
     * @private
     */
    static _addEvent(ctx, where, eventName, callback){
        if(_legal("string",eventName) && _legal("function",callback)) {
            if (!ctx._events) ctx._events = {};
            if (!ctx._events[where]) ctx._events[where] = {};
            if (!ctx._events[where][eventName]) ctx._events[where][eventName] = [];

            if (_exist(ctx._events[where], eventName, callback) === -1) ctx._events[where][eventName].push(callback);
        }
    }

    /**
     * Add function to event list
     *
     * @method on
     * @param {string} eventName - Name of the event the function has to listen to
     * @param {function} callback - Function that has te be triggered on the event
     * @return null
     */
    on(eventName, callback){
        this.constructor._addEvent(this,'on', eventName, callback);
    }
    /**
     * Add function to event once list
     *
     * @method once
     * @param {string} eventName - Name of the event the function has to listen to
     * @param {function} callback - Function that has te be triggered on the event
     * @return null
     */
    once(eventName, callback){
        this.constructor._addEvent(this,'once', eventName, callback);
    }
    /**
     * Trigger the event with optional arguments

     * @method trigger
     * @param {string} eventName - Name of the event that has to be triggered
     * @param {...*} [params] - Extra parameters to be set as argument array for callback functions
     * @return null
     */
    trigger(eventName, params){
        if(_legal("string",eventName) && this._events) {
            const args = Array.apply(null, arguments);
            args.shift();

            let fns = ( this._events.on && this._events.on[eventName] )? this._events.on[eventName].concat() : [];
            if( this._events.once && this._events.once[eventName] ){
                fns = fns.concat(this._events.once[eventName]);
                this._events.once[eventName] = [];
            }

            for(let i=0; i<fns.length; i++) fns[i].apply(fns[i], args);
        }
    }
    /**
     * Removes function from the event list and once list
     *
     * @method off
     * @param {string} eventName - Name of the event the function has to be removed from
     * @param {function} [callback] - Function that has to be removed from the list
     * @return null
     */
    off(eventName, callback){
        if(_legal('string', eventName) && this._events){
            if(_legal("function", callback)) {
                if(this._events.on){
                    let i = _exist(this._events.on, eventName, callback);
                    if (i !== -1) this._events.on[eventName].splice(i, 1);
                }
                if(this._events.once){
                    let i = _exist(this._events.once, eventName, callback);
                    if (i !== -1) this._events.once[eventName].splice(i, 1);
                }
            } else if(callback === undefined){
                if (this._events.on) delete this._events.on[eventName];
                if (this._events.once) delete this._events.once[eventName];
            }
        }
    }
    /**
     * Removes all callback functions and events from the main object
     *
     * @method kill
     * @return null
     */
    kill(){
        delete this._events;
    }
}
export default BaseEvents;