import React, {Component} from 'react';
import {observer, inject} from "mobx-react";
import LoopCopy from './LoopCopy';

function _isTouchDevice(){
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}

export default inject('store')(observer(class LoopOneOverlay extends Component {

    constructor(props){
        super(props);

        this.el = React.createRef();

        const items = this.props.store.loopSettings.items.map(item => {return{...item, showing: false}});
        this.state  = {
            items    : items,
            show     : false
        };

        this.onAudioUpdate = this.onAudioUpdate.bind(this);
        this.audioElement = document.getElementById('audioWhispering');
        this.audioElement.addEventListener('timeupdate', this.onAudioUpdate);
    }

    componentDidMount(){
        // do the animate in!
        setTimeout(() => {
            this.setState({show: true});
        }, 10);
    }

    componentWillUnmount(){
        this.audioElement.removeEventListener('timeupdate', this.onAudioUpdate);
        this.audioElement = null;
    }

    onAudioUpdate(){
        const {currentTime} = this.audioElement;

        const newItems = this.state.items.map(item => {
            item.show = (currentTime > item.t && currentTime < item.t + 8);
            return item;
        });
        this.setState({items: newItems});
    }

    static renderContentItem(item, key){
        const style = {left: item.x+'%', top: item.y+'%'};
        const className = item.show ? 'popup animate' : 'popup';

        return (<span key={key} className={className} style={style}><LoopCopy index={item.copy}/></span>);
    }

    renderHint(){
        if(this.props.store.isSamsungGayAssBrowser) return null;

        const message = _isTouchDevice()
            ? 'tap to check phone'
            : 'click to check phone';

        const className = (this.props.store.showingContinueButton)? 'center' : 'center hide';

        return (
            <div className={className}>
                <button className="btn">{message}</button>
            </div>);
    }

    render(){
        const {loopSettings, inputDown} = this.props.store;

        let className = !(loopSettings)? "messages-overlay loop-one hide" : 'messages-overlay loop-one';
        if(this.state.show) className += ' show';
        if(inputDown) className += ' ' + loopSettings.className;


        return (<div className={className} ref={this.el}>
            {this.state.items.map(this.constructor.renderContentItem)}
            {this.renderHint()}
        </div>);
    }
}));