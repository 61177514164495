import React, {Component} from 'react';
import {observer, inject} from "mobx-react";
// import videoFile from './../media/preview.mp4';
import posterFile from './../media/poster.jpg';

import Snow from './components/Snow';
import Wiebertjes from './components/Wiebertjes';
import RotatePhone from './components/RotatePhone';
import Video from './components/Video';
import LoopOneOverlay from './components/LoopOneOverlay';
import LoopTwoOverlay from './components/LoopTwoOverlay';
import LoopThreeOverlay from './components/LoopThreeOverlay';
import EndScreen from './components/EndScreen';
import Audios from './components/Audios';
import Icon from './components/Icon';

const isGayAssLinkedinAppOnIphone = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);

const videoFile = isGayAssLinkedinAppOnIphone
    ? 'https://player.vimeo.com/external/307720853.hd.mp4?s=5d79df84ccdc6931081881e395b2981046d35108&profile_id=174' // linkedin gay ass video
    : 'https://player.vimeo.com/external/307525112.hd.mp4?s=c470e748bcc2ab50c187027afd532b6d05c3bd16&profile_id=174'; // normal badass video

function _isTouchDevice(){
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}

export default inject('store')(observer(class App extends Component {

    constructor(props){
        super(props);
        this.mq = window.matchMedia('(orientation: portrait)');

        this.state = {
            portrait: false//this.mq.matches
        };

        this.ratioWrapper = React.createRef();
        this.playOverlay  = React.createRef();

        // yeah this is ugly, but orientation change needs to be separate. On some devices this triggers "before" viewport size update instead of after
        this.onResize          = this.onResize.bind(this);
        this.orientationChange = () => {
            setTimeout(() => {
                this.checkOrientation();
                this.onResize();
            }, 500);
        };
        window.addEventListener('resize', this.onResize);
        window.addEventListener('orientationchange', this.orientationChange);
    }

    componentDidMount(){
        this.checkOrientation();
        this.onResize();
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('orientationchange', this.orientationChange);
    }

    onResize(){
        const el = this.ratioWrapper.current;

        const maxWidthByWidth  = this.state.portrait? window.innerHeight : window.innerWidth;
        const maxWidthByHeight = this.state.portrait? window.innerWidth / 9 * 16 : window.innerHeight / 9 * 16;
        const width            = Math.max(320, Math.min(maxWidthByWidth, maxWidthByHeight));
        el.style.width         = width.toString() + 'px';
        el.style.height        = (width / 16 * 9).toString() + 'px';


        const play = this.playOverlay.current;
        if(play){
            const mwbw        = window.innerWidth;
            const mwbh        = window.innerHeight / 9 * 16;
            const w           = Math.max(640, Math.min(mwbw, mwbh));
            play.style.width  = w.toString() + 'px';
            play.style.height = (w / 16 * 9).toString() + 'px';
        }
    }

    checkOrientation(){
        // this.setState({
        //     portrait: this.mq.matches
        // });
    }

    onOverlayClick(){
        if(!this.props.store.playing){
            this.props.store.start();
        } else {
            // this.props.store.pause();
        }
    }

    onReWatchClick(){
        if(!this.props.store.playing){
            this.props.store.start();
        }
    }

    renderPlayButton(playing, status, States){
        if(playing || status === States.VIDEO_ENDED) return null;

        /// fallback
        if(status === States.LOOP_ONE && this.props.store.isSamsungGayAssBrowser){

            const message = _isTouchDevice()
                ? 'tap to check phone'
                : 'click to check phone';

            return (<div className="messages-overlay loop-one show" onClick={this.onOverlayClick.bind(this)}>
                <div className="center">
                    <button className="btn">{message}</button>
                </div>
            </div>);
        } else if(status === States.LOOP_TWO && this.props.store.isSamsungGayAssBrowser){
            const message = _isTouchDevice()
                ? 'tap to put down phone'
                : 'click to put down phone';

            return (<div className="messages-overlay loop-two show" onClick={this.onOverlayClick.bind(this)}>
                <div className="center">
                    <button className="btn">{message}</button>
                </div>
            </div>);
        }
        /// fallback

        const style = {backgroundImage: `url(${posterFile})`};

        return (<div className="play-overlay" onClick={this.onOverlayClick.bind(this)}>
            <div className="center-ratio" style={style} ref={this.playOverlay}>
                <span className="play-button">
                <Icon icon="play"/>
            </span>
            </div>
        </div>);
    }

    renderVideoWrapper(inputDown, loopSettings){
        const contentClassName = (inputDown && loopSettings)? 'video-content ' + loopSettings.className : 'video-content';

        return (<div className={contentClassName}>
            <Video src={videoFile} poster={posterFile} playInline={!isGayAssLinkedinAppOnIphone}/>
            <div className="video-overlay" onClick={this.onOverlayClick.bind(this)}/>
        </div>);
    }

    render(){
        const {inputDown, loopSettings, status, playing} = this.props.store;
        const {States}                                   = this.props.store.constructor;

        let loopOverlay = null;
        switch(status){
            case States.LOOP_ONE:
                loopOverlay = (<LoopOneOverlay/>);
                break;
            case States.LOOP_TWO:
                loopOverlay = (<LoopTwoOverlay/>);
                break;
            case States.LOOP_THREE:
                loopOverlay = (<LoopThreeOverlay/>);
                break;
            default:
                break;
        }

        const endScreen = (status === States.VIDEO_ENDED)?
            <EndScreen reWatch={this.onReWatchClick.bind(this)} meta={this.props.meta}/> :
            null;

        let ratioClass = this.state.portrait? 'ratio-wrapper portrait' : 'ratio-wrapper';
        if(status === States.PRE_VIDEO){
            ratioClass += ' hide';
        }


        return (
            <div className={'content ' + status}>
                <Wiebertjes/>
                <Snow/>
                <RotatePhone playing={playing}/>
                <div className={ratioClass} ref={this.ratioWrapper}>

                    {this.renderVideoWrapper(inputDown, loopSettings)}
                    {loopOverlay}
                    {endScreen}
                </div>
                {this.renderPlayButton(playing, status, States)}
                <Audios linkedin={isGayAssLinkedinAppOnIphone}/>
            </div>
        );
    }
}));