import React, {Component} from 'react';
import {observer, inject} from "mobx-react";
import CursorHider from './../utils/CursorHider';

// const ga = window.ga || function(){};

export default inject('store')(observer(class Video extends Component {

    constructor(props){
        super(props);
        this.cursorHider = new CursorHider();

        this.onPlay  = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onEnded = this.onEnded.bind(this);

        this.video = React.createRef();
    }

    componentDidMount(){
        this.props.store.setVideo(this.video.current);
    }

    componentWillUnmount(){
        this.props.store.setVideo(null);
        this.cursorHider.dispose();
    }

    componentDidUpdate(){
        this.props.store.setVideo(this.video.current);
    }

    onPlay(){
        this.cursorHider.setPlaying(true);
        const s = this.props.store;
        s.setVideoStatus(s.constructor.VideoStatus.PLAYING);
        // ga('send','event','Video','play');
        this.props.store.track('Video', 'play', 'main-video');
    }

    onPause(){
        this.cursorHider.setPlaying(false);
        const s = this.props.store;
        s.setVideoStatus(s.constructor.VideoStatus.NOT_PLAYING);
    }

    onEnded(){
        this.cursorHider.setPlaying(false);
        const s = this.props.store;
        s.setVideoStatus(s.constructor.VideoStatus.NOT_PLAYING);
        s.setStatus(s.constructor.States.VIDEO_ENDED);
        // ga('send','event','Video','ended');
        this.props.store.track('Video', 'ended', 'main-video');
    }

    render(){
        return (<video playsInline={this.props.playInline}
                       ref={this.video}
                       src={this.props.src}
                       poster={this.props.poster}
                       onPlay={this.onPlay}
                       onPause={this.onPause}
                       onEnded={this.onEnded}
        />);
    }
}));