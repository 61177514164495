import React, {Component} from 'react';
import {observer, inject} from "mobx-react";
import whisper from './../../media/whisper.mp3';
import sigh from './../../media/sigh.mp3';
import {TweenMax} from 'gsap';
import Debug from './../utils/Debug';

const FADE_TIME = .5;

/**
 *
 * @return {number[]}
 */
function iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
    else return [0];
}

export default inject('store')(observer(class Audios extends Component {

    constructor(props){
        super(props);
        window.aa = this;

        this.one            = {
            loop : React.createRef(),
            input: React.createRef()
        };

        this.loopOne = React.createRef();
        this.loopTwo = React.createRef();

        this.onStart        = this.onStart.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);

        this.props.store.on('video-start', this.onStart);
        this.props.store.on('set-status', this.onStatusChange);
    }

    componentDidMount(){
        this.loopOne.current.volume  = 0;
        this.loopTwo.current.volume = 0;
        if(iOSversion()[0] > 0){
            this.loopOne.current.muted = true;
            this.loopTwo.current.muted = true;
        }
    }

    componentWillUnmount(){

        this.props.store.off('video-start', this.onStart);
        this.props.store.off('set-status', this.onStatusChange);
    }

    /**
     *
     * @param {HTMLAudioElement} el
     * @param {number} val
     */
    static setVolume(el, val){
        if(val === 1) el.currentTime = 0;

        if(iOSversion()[0] > 0){
            el.muted = val === 0;
        } else {
            TweenMax.to(el, FADE_TIME, {volume: val});
        }
    }

    onStart(){
        if(this.props.linkedin)return null;

        if(this.loopOne.current) this.loopOne.current.play();
        if(this.loopTwo.current) this.loopTwo.current.play();
    }

    onStatusChange(previous, current){
        if(this.props.linkedin)return null;

        const {States} = this.props.store.constructor;
        if(previous === States.INTRO && current === States.LOOP_ONE){
            // start loop audio
            Debug.log('--Audio | statusChange | start audio loop');

            this.constructor.setVolume(this.loopOne.current, 1);
        } else if(previous === States.LOOP_ONE && current === States.WATCHING_PHONE){
            // stop loop audio
            Debug.log('--Audio | statusChange | stop audio loop');

            this.constructor.setVolume(this.loopOne.current, 0);
        }

        if(previous === 'watching-phone' && current === States.LOOP_TWO) {
            this.constructor.setVolume(this.loopTwo.current, 1);
        } else if(previous === States.LOOP_TWO && current === States.SUSPICIOUS){
            this.constructor.setVolume(this.loopTwo.current, 0);
        }

    }

    render(){
        return (<div className="audios">
            <audio id="audioWhispering" src={whisper} ref={this.loopOne} loop/>
            <audio src={sigh} ref={this.loopTwo} loop/>
        </div>);
    }
}));