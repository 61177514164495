import 'babel-polyfill';
import './scss/app.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'mobx-react';
import Debug from './js/utils/Debug';
// import './js/snow';
import VideoStore from './js/stores/VideoStore';
import App from './js/App';
// import * as serviceWorker from './serviceWorker';
Debug.setOptions({
    enabled: false
});


const settings = [
    {
        name : 'intro',
        start: 0,
        input: false,
        state: 'INTRO',
        loop : false
    },
    {
        name : 'watch-phone',
        start: 45.88,
        state: 'LOOP_ONE',
        loop : {
            buttonAt: 50,
            items   : [
                {
                    t   : 0,
                    copy: 'hey',
                    x   : 21,
                    y   : 20

                }, {
                    t   : .2,
                    copy: 'pssst',
                    x   : 60,
                    y   : 12

                }, {
                    t   : 1,
                    copy: 'just_check_your_phone',
                    x   : 40,
                    y   : 76

                }, {
                    t   : 2.8,
                    copy: 'come_on',
                    x   : 75,
                    y   : 27

                }, {
                    t   : 4,
                    copy: 'just_check_your_phone',
                    x   : 63,
                    y   : 69

                }, {
                    t   : 6.4,
                    copy: 'just_for_a_little_bit',
                    x   : 19,
                    y   : 69

                }, {
                    t   : 8.4,
                    copy: 'you_know_you_want_it',
                    x   : 34,
                    y   : 21

                }, {
                    t   : 11,
                    copy: 'maybe_its_you_know_who',
                    x   : 75,
                    y   : 76

                }, {
                    t   : 13.2,
                    copy: 'maybe_its_work',
                    x   : 75,
                    y   : 16

                }, {
                    t   : 16.6,
                    copy: 'could_be_important',
                    x   : 25,
                    y   : 23

                }, {
                    t   : 18.4,
                    copy: 'just_check_your_phone',
                    x   : 20,
                    y   : 84

                }, {
                    t   : 21.6,
                    copy: 'come_on',
                    x   : 52,
                    y   : 23

                }, {
                    t   : 23.6,
                    copy: 'maybe_its_you_know_who',
                    x   : 53,
                    y   : 78

                }, {
                    t   : 26.2,
                    copy: 'you_know_you_want_it',
                    x   : 70,
                    y   : 32

                }, {
                    t   : 29.6,
                    copy: 'come_on',
                    x   : 60,
                    y   : 82

                }, {
                    t   : 30.6,
                    copy: 'you_know_you_want_it',
                    x   : 43,
                    y   : 12

                }
            ]
        }
    },
    {
        name : 'watching-phone',
        start: 57.08,
        input: false,
        state: 'WATCHING_PHONE'
    },
    {
        name : 'phone-away',
        start: 65.64,
        loop : {
            buttonAt: 82,
            items   : []
        },
        state: 'LOOP_TWO'
    },
    {
        name : 'suspicious',
        start: 85.08,
        input: false,
        state: 'SUSPICIOUS'
    },
    {
        name : 'ask-see-missing',
        start: 95.08,
        loop : {
            buttonAt: 96,
            items   : []
        },
        state: 'LOOP_THREE',

    },
    {
        name : 'carnaval',
        start: 103.72,
        input: false,
        state: 'CARNAVAL'
    },
];
let meta       = {};
try{
    meta = {
        title      : document.getElementById('metaTitle').getAttribute('content'),
        description: document.getElementById('metaDescription').getAttribute('content'),
        url        : document.getElementById('metaUrl').getAttribute('href'),
    };
} catch(e){
    Debug.error('Failed loading meta data from meta tags', e);
}

const videoStore  = new VideoStore(settings);
window.Debug      = Debug;
window.vs = videoStore;

ReactDOM.render(<Provider store={videoStore}>
    <App meta={meta}/>
</Provider>, document.getElementById('root'));