const _defaultStyle = "font-weight:bold;";

let _enabled = false;
let _enabledLevels = [{
    'default': _defaultStyle
}];

const _newArguments = function (start, args) {
    args = (args.length === 1) ? [args[0]] : Array.apply(null, args);

    args.splice(0, start);
    return args;
};

const _addLevels = function (existing, _new) {
    let newObject = {};

    _new = _new || false;

    if (_new.constructor === String) {
        newObject[_new] = _defaultStyle;

    } else if (_new.constructor === Array) {
        for (let i = 0; i < _new.length; i++) {
            newObject[_new[i]] = _defaultStyle;
        }
    } else if (_new.constructor === Object) {
        for (const k in _new) {
            if (_new.hasOwnProperty(k) && _new[k].constructor === String) {
                newObject[k] = _new[k];
            } else {
                newObject[k] = _defaultStyle;
            }
        }
    } else {
        newObject = {
            'default': _defaultStyle
        };
    }

    for (const l in newObject) {
        if(newObject.hasOwnProperty(l))existing[l] = newObject[l];
    }

    return existing;
};

const _removeLevels = function (existing, _tbr) { // _tbr = to be removed
    if (_tbr.constructor === String) {
        delete existing[_tbr];

    } else if (_tbr.constructor === Array) {
        for (let i = 0; i < _tbr.length; i++) {
            delete existing[_tbr[i]];
        }
    } else if (_tbr.constructor === Object) { // is here for convenience.
        for (const k in _tbr) {
            if (_tbr.hasOwnProperty(k)) delete existing[k];
        }
    }

    return existing;
};

const _getTimeStamp = function () {
    const d = new Date();
    return (d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) + ' ' + d.getHours() + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2) + '.' + ('00' + d.getMilliseconds()).slice(-3));
};

/*
 * is for terminal, not browser
 * let _colorConsole = function(args, levelDepth ,level){
 *     level = level || 0;
 *     for(let i=0; i<args.length; i++){
 *         if(args[i].constructor === Number){
 *             args[i] = "\033[33m"+ args[i].toString() +"\033[0m";// color yellow
 *
 *         } else if(args[i].constructor === Boolean){
 *             args[i] = "\033[33m"+ args[i].toString() +"\033[0m";// color magenta = 35
 *
 *         } else if(args[i].constructor === String && (i > 0 || level !== 0) ){
 *             args[i] = "\033[32m'"+ args[i] + "'\033[0m";  // color green
 *         } else if(args[i].constructor === Array){
 *             if(level < levelDepth)args[i] = '[ '+_colorConsole(args[i], levelDepth, level+1).join(', ')+ ' ]';
 *             else args[i] = '[ '+args[i].join(', ')+' ]';
 *
 *             // } else if(args[i].constructor === Object){ // this one is not working correctly yet.....
 *             // 	if(level <= levelDepth){
 *             // 		for(let k in args[i]){
 *
 *             // 			if(args[i].hasOwnProperty(k))args[i][k] = _colorConsole(args[i][k], levelDepth, level+1);
 *             // 		}
 *             //	}
 *         }
 *     }
 *
 *     return args;
 * };
 */

const _Debug = function (type, args, style) {
    // if(args[0].constructor === String) args[0] = style + _getTimeStamp() + ' | %c' + args[0];
    // else args.unshift(style + _getTimeStamp() + " ");
    switch (type) {
        case "log":
        case "warn":
        case "error":
        case "info":
            if (args[0].constructor === String) {
                args[0] = _getTimeStamp() + " | %c" + args[0];
                args.splice(1, 0, style);
            } else {
                args.unshift(_getTimeStamp() + ' |');
            }

            console[type].apply(console, args);
            break;
        case "dir":
            console.log(_getTimeStamp());
            for (let i = 0; i < args.length; i++) {
                console.dir(args[i]);
            }
            break;
        case "assert":
            args.splice(1, 0, _getTimeStamp() + ' |');
            console.assert.apply(console, args);
            break;
        default:
            break;
    }
};

const _debug = function (type, level, style, args) {
    if (_enabled && _enabledLevels.hasOwnProperty(level)) {
        if (!style) style = _enabledLevels[level];

        _Debug(type, args, style);
    }
};

const Debug = {

    /**
     * Setup for the Debugger
     *
     * @method setOptions
     * @param {object} [options] options to set
     * @param {boolean} [options.enabled] enable or disable the debugger
     * @param {string|array|object} [options.enabledLevels] Debug levels to enable
     * @returns {void} no return
     */
    setOptions: function (options) {
        options = (options && options.constructor === Object) ? options : {};

        _enabled = (options.enabled === true);
        _enabledLevels = _addLevels({'default': _defaultStyle}, options.enabledLevels);
    },
    isEnabled: function () {
        return _enabled;
    },
    enabledLevels: function () {
        return _enabledLevels;
    },

    /**
     * Enable debugging.
     *
     * @method enable
     * @returns {void} no return
     */
    enable: function () {
        _enabled = true;
    },

    /**
     * Disable debugging.
     *
     * @method disable
     * @return {null} no return
     */
    disable: function () {
        _enabled = false;
    },

    /**
     * Enable debugging on a list of debug levels.
     *
     * @method enableLevels
     * @param {String|Array|Object} levels The list of debug levels to enable.
     * @returns {void} no return
     * @example
     *        Debug.enableLevels('myCustomLevel');
     *        Debug.enableLevels(['customLevel1','customLevel2']);
     *        Debug.enableLevels({customLevel1: "\033[1;31m", customLevel2: "\033[32m"});
     */
    enableLevels: function (levels) {
        _enabledLevels = _addLevels(_enabledLevels, levels);
    },

    /**
     * Disable debugging on a list of debug levels.
     *
     * @method disableLevels
     * @param {String|Array|Object} levels The list of debug levels to enable.
     * @returns {void} no return
     * @example
     *        Debug.disableLevels('myCustomLevel');
     *        Debug.disableLevels(['customLevel1','customLevel2']);
     *        Debug.disableLevels({customLevel1: "\033[1;31m", customLevel2: "\033[32m"});
     */
    disableLevels: function (levels) {
        _enabledLevels = _removeLevels(_enabledLevels, levels);
    },

    /**
     * Enable or disable debugging.
     *
     * @method set
     * @param {Boolean} bool set debugging on true or false
     * @returns {void} no return
     */
    set: function (bool) {
        _enabled = !(!bool);
    },

    /**
     * Outputs a message to the Console.
     * Uses debug level 'default'.
     *
     * @method log
     * @param {...*} args to output.
     * @returns {void} no return
     * @example
     *        Debug.log('Hello World!');
     *        Debug.log('Hello World!', MyObject, 16);
     */
    log: function (args) {
        _debug('log', 'default', false, _newArguments(0, arguments));
    },

    /**
     * Outputs a message to the Console.
     *
     * @method logL
     * @param {String} level The debug level for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.logL('myCustomLevel',Hello World!');
     *        Debug.logL('myCustomLevel',Hello World!', MyObject, 16);
     */
    logL: function (level, args) {
        _debug('log', level, false, _newArguments(1, arguments));
    },

    /**
     * Outputs a message to the Console.
     *
     * @method logS
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.logS('\033[1m','Hello World!');
     *        Debug.logS('\033[31m',Hello World!', MyObject, 16);
     */
    logS: function (style, args) {
        _debug('log', 'default', style, _newArguments(1, arguments));
    },

    /**
     * Outputs a message to the Console.
     *
     * @method logE
     * @param {String} level The debug level for the output
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.logE('myCustomLevel','\033[1m','Hello World!');
     *        Debug.logE('myCustomLevel','\033[31m',Hello World!', MyObject, 16);
     */
    logE: function (level, style, args) {
        _debug('log', level, style, _newArguments(2, arguments));
    },

    /**
     * Outputs a warning message to the Console.
     * Uses debug level 'default'.
     *
     * @method warn
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.warn('Hello World!');
     *        Debug.warn('Hello World!', MyObject, 16);
     */
    warn: function (args) {
        _debug('warn', 'default', false, _newArguments(0, arguments));
    },

    /**
     * Outputs a warning message to the Console.
     *
     * @method warnL
     * @param {String} level The debug level for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.warnL('myCustomLevel',Hello World!');
     *        Debug.warnL('myCustomLevel',Hello World!', MyObject, 16);
     */
    warnL: function (level, args) {
        _debug('warn', level, false, _newArguments(1, arguments));
    },

    /**
     * Outputs a warning message to the Console.
     *
     * @method warnS
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.warnS('\033[1m','Hello World!');
     *        Debug.warnS('\033[31m',Hello World!', MyObject, 16);
     */
    warnS: function (style, args) {
        _debug('warn', 'default', style, _newArguments(1, arguments));
    },
    /**
     * Outputs a warning message to the Console.
     *
     * @method warnE
     * @param {String} level The debug level for the output
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.warnE('myCustomLevel','\033[1m','Hello World!');
     *        Debug.warnE('myCustomLevel','\033[31m',Hello World!', MyObject, 16);
     */
    warnE: function (level, style, args) {
        _debug('warn', level, style, _newArguments(2, arguments));
    },

    /**
     * Outputs an info message to the Console.
     * Uses debug level 'default'.
     *
     * @method info
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.info('Hello World!');
     *        Debug.info('Hello World!', MyObject, 16);
     */
    info: function (args) {
        _debug('info', 'default', false, _newArguments(0, arguments));
    },

    /**
     * Outputs an info message to the Console.
     *
     * @method infoL
     * @param {String} level The debug level for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.infoL('myCustomLevel',Hello World!');
     *        Debug.infoL('myCustomLevel',Hello World!', MyObject, 16);
     */
    infoL: function (level, args) {
        _debug('info', level, false, _newArguments(1, arguments));
    },

    /**
     * Outputs an info message to the Console.
     *
     * @method infoS
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.infoS('\033[1m','Hello World!');
     *        Debug.infoS('\033[31m',Hello World!', MyObject, 16);
     */
    infoS: function (style, args) {
        _debug('info', 'default', style, _newArguments(1, arguments));
    },

    /**
     * Outputs an info message to the Console.
     *
     * @method infoE
     * @param {String} level The debug level for the output
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.infoE('myCustomLevel','\033[1m','Hello World!');
     *        Debug.infoE('myCustomLevel','\033[31m',Hello World!', MyObject, 16);
     */
    infoE: function (level, style, args) {
        _debug('info', level, style, _newArguments(2, arguments));
    },

    /**
     * Outputs an error message to the Console.
     * Uses debug level 'default'.
     *
     * @method error
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.error('Hello World!');
     *        Debug.error('Hello World!', MyObject, 16);
     */
    error: function (args) {
        _debug('error', 'default', false, _newArguments(0, arguments));
    },

    /**
     * Outputs an error message to the Console.
     *
     * @method errorL
     * @param {String} level The debug level for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.errorL('myCustomLevel',Hello World!');
     *        Debug.errorL('myCustomLevel',Hello World!', MyObject, 16);
     */
    errorL: function (level, args) {
        _debug('error', level, false, _newArguments(1, arguments));
    },

    /**
     * Outputs an error message to the Console.
     *
     * @method errorS
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.errorS('\033[1m','Hello World!');
     *        Debug.errorS('\033[31m',Hello World!', MyObject, 16);
     */
    errorS: function (style, args) {
        _debug('error', 'default', style, _newArguments(1, arguments));
    },

    /**
     * Outputs an error message to the Console.
     *
     * @method errorE
     * @param {String} level The debug level for the output
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.errorE('myCustomLevel','\033[1m','Hello World!');
     *        Debug.errorE('myCustomLevel','\033[31m',Hello World!', MyObject, 16);
     */
    errorE: function (level, style, args) {
        _debug('error', level, style, _newArguments(2, arguments));
    },

    /**
     * Outputs an assert message to the Console.
     * Uses debug level 'default'.
     *
     * @method assert
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.assert('Hello World!');
     *        Debug.assert('Hello World!', MyObject, 16);
     */
    assert: function (args) {
        _debug('assert', 'default', false, _newArguments(0, arguments));
    },

    /**
     * Outputs an assert message to the Console.
     *
     * @method assertL
     * @param {String} level The debug level for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.assertL('myCustomLevel',Hello World!');
     *        Debug.assertL('myCustomLevel',Hello World!', MyObject, 16);
     */
    assertL: function (level, args) {
        _debug('assert', level, false, _newArguments(1, arguments));
    },

    /**
     * Outputs an assert message to the Console.
     *
     * @method assertS
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.assertS('\033[1m','Hello World!');
     *        Debug.assertS('\033[31m',Hello World!', MyObject, 16);
     */
    assertS: function (style, args) {
        _debug('assert', 'default', style, _newArguments(1, arguments));
    },

    /**
     * Outputs an assert message to the Console.
     *
     * @method assertE
     * @param {String} level The debug level for the output
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.assertE('myCustomLevel','\033[1m','Hello World!');
     *        Debug.assertE('myCustomLevel','\033[31m',Hello World!', MyObject, 16);
     */
    assertE: function (level, style, args) {
        _debug('assert', level, style, _newArguments(2, arguments));
    },

    /**
     * Outputs a dir message to the Console.
     * Uses debug level 'default'.
     *
     * @method dir
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.dir('Hello World!');
     *        Debug.dir('Hello World!', MyObject, 16);
     */
    dir: function (args) {
        _debug('dir', 'default', false, _newArguments(0, arguments));
    },

    /**
     * Outputs a dir message to the Console.
     *
     * @method dirL
     * @param {String} level The debug level for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.dirL('myCustomLevel',Hello World!');
     *        Debug.dirL('myCustomLevel',Hello World!', MyObject, 16);
     */
    dirL: function (level, args) {
        _debug('dir', level, false, _newArguments(1, arguments));
    },

    /**
     * Outputs a dir message to the Console.
     *
     * @method dirS
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.dirS('\033[1m','Hello World!');
     *        Debug.dirS('\033[31m',Hello World!', MyObject, 16);
     */
    dirS: function (style, args) {
        _debug('dir', 'default', style, _newArguments(1, arguments));
    },

    /**
     * Outputs a dir message to the Console.
     *
     * @method dirE
     * @param {String} level The debug level for the output
     * @param {String} style The debug style for the output
     * @param {...*} args Objects to output.
     * @returns {void} no return
     * @example
     *        Debug.dirE('myCustomLevel','\033[1m','Hello World!');
     *        Debug.dirE('myCustomLevel','\033[31m',Hello World!', MyObject, 16);
     */
    dirE: function (level, style, args) {
        _debug('dir', level, style, _newArguments(2, arguments));
    }
};

export default Debug;