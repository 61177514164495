import React, {Component} from 'react';

class SnowFlake {
    constructor(canvas){
        this.x = 0;
        this.y = 0;
        this.vx = 0;
        this.vy = 0;
        this.radius = 0;
        this.alpha = .5;

        this.reset(canvas);
    }
    reset(canvas){
        this.x = this.constructor._randomBetween(0, canvas.width);
        this.y = this.constructor._randomBetween(0, -canvas.height);
        this.vx = this.constructor._randomBetween(-1, 1);
        this.vy = this.constructor._randomBetween(1, 5);
        this.radius = this.constructor._randomBetween(1, 4);
        this.alpha = this.constructor._randomBetween(.1,.9);
    }

    static _randomBetween(min, max){
        return min + Math.random() * (max - min);
    }

    update(canvas){
        this.x+= this.vx;
        this.y+= this.vy;

        if( this.x-this.radius > canvas.width ||
            this.x+this.radius < 0 ||
            this.y-this.radius > canvas.height) {

            this.reset(canvas);
        }
    }
}

class CanvasSnow {
    constructor(canvas){
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d');
        this.amount =  window.innerHeight / 4;

        this.onResize = this.onResize.bind(this);
        window.addEventListener('resize', this.onResize);
        window.addEventListener('orientationchange', ()=>{
            setTimeout(this.onResize, 100);
        });
        this.onResize();

        /**
         *
         * @type {SnowFlake[]}
         */
        this.flakes = [];

        this.build();
        this.update = this.update.bind(this);
        requestAnimationFrame(this.update);
    }

    build(){
        this.flakes = [];
        for(let i = 0; i < this.amount; i++){
            this.flakes.push(new SnowFlake(this.canvas));
        }
    }

    onResize(){
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
    }

    update(){
        this.ctx.clearRect(0,0, this.canvas.width, this.canvas.height);

        this.flakes.forEach(flake => {
            flake.update(this.canvas);

            this.ctx.save();
            this.ctx.fillStyle = '#fff';
            this.ctx.beginPath();
            this.ctx.arc(flake.x, flake.y, flake.radius, 0, Math.PI*2);
            this.ctx.closePath();
            this.ctx.globalAlpha = flake.alpha;
            this.ctx.fill();
            this.ctx.restore();
        });

        requestAnimationFrame(this.update);
    }
}


export default class Snow extends Component {
    constructor(props){
        super(props);

        this.el = React.createRef();
    }

    componentDidMount(){
        new CanvasSnow(this.el.current);
    }

    render(){
        return (<canvas className="snow" ref={this.el}/>);
    }
}