
class CursorHider {

    constructor(){
        this.visible = true;
        this.playing = false;

        this.timeout = null;
        this.TIMEOUT_TIME = 2200;
        this.onMoveTimeoutDone = this.onMoveTimeoutDone.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);

        window.addEventListener('mousemove', this.onMouseMove);
    }

    dispose(){
        window.addEventListener('mousemove', this.onMouseMove);
    }

    setPlaying(isPlaying){
        this.playing = isPlaying;
        if(isPlaying){
            this.resetTimeout();
        } else {
            clearTimeout(this.timeout);
            this.onMouseMove();
        }
    }

    resetTimeout(){
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.onMoveTimeoutDone, this.TIMEOUT_TIME);
    }

    onMouseMove(){
        if(!this.visible){
            this.visible = true;
            document.body.classList.remove('hide-cursor');
        }

        if(this.playing) this.resetTimeout();
    }

    onMoveTimeoutDone(){
        if(this.playing) {
            this.visible = false;
            document.body.classList.add('hide-cursor');
        }
    }
}

export default CursorHider;