import React, {Component} from 'react';

export default class Wiebertjes extends Component {

    constructor(props){
        super(props);

        this.state = {
            orientation: 'landscape'
        };

        // yeah this is ugly, but orientation change needs to be separate. On some devices this triggers "before" viewport size update instead of after
        this.onResize          = this.onResize.bind(this);
        this.orientationChange = () => {
            setTimeout(this.onResize, 100);
        };
        window.addEventListener('resize', this.onResize);
        window.addEventListener('orientationchange', this.orientationChange);
    }

    componentDidMount(){
        this.onResize();
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('orientationchange', this.orientationChange);
    }

    onResize(){
        const newOrientation = window.innerWidth / window.innerHeight > 16 / 9 ? 'landscape' : 'portrait';

        if(this.state.orientation !== newOrientation){
            this.setState({orientation: newOrientation});
        }
    }

    render(){
        return (<div className={`wiebertjes ${this.state.orientation}`}>
            <div className="w top">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 175.71">
                    <title>Top_</title>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <polygon className="cls-4" points="380.65 160.76 317.66 97.78 380.65 34.79 380.65 160.76"/>
                            <polygon className="cls-5" points="562.85 93.58 656.42 0 750 93.58 562.85 93.58"/>
                            <polygon className="cls-2" points="92.17 175.71 4.53 88.06 92.17 0.42 92.17 175.71"/>
                            <polygon className="cls-3" points="412.75 2.64 317.88 97.5 223.01 2.64 412.75 2.64"/>
                            <polygon className="cls-1" points="0 89.08 0 0 89.08 0 0 89.08"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div className="w bottom">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 753.99 257.43">
                    <title>Bottom_</title>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <polygon className="cls-1" points="0 82.49 87.47 169.96 0 257.43 0 82.49"/>
                            <polygon className="cls-6" points="329.97 251.14 242.32 163.49 329.97 75.85 329.97 251.14"/>
                            <polygon className="cls-3" points="666.81 0 754 87.61 666.81 175 579.31 87.5 666.81 0"/>
                            <polygon className="cls-4" points="169.47 251.51 87.17 251.51 87.17 169.22 169.47 251.51"/>
                            <polygon className="cls-2"
                                     points="667.06 175.86 579.66 88.16 420.45 88.23 508.04 175.86 667.06 175.86"/>
                            <polygon className="cls-5" points="587.17 257.43 667.6 177.01 748.02 257.43 587.17 257.43"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div className="w left">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.65 1080">
                    <title>Links_</title>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <polygon className="cls-1" points="312.9 99.28 479.65 266.03 312.9 432.77 312.9 99.28"/>
                            <polygon className="cls-2" points="0.67 432.65 156.85 276.47 313.02 432.65 0.67 432.65"/>
                            <polygon className="cls-3"
                                     points="469.38 923.42 313.26 1078.78 157.53 923.42 313.45 767.5 469.38 923.42"/>
                            <polygon className="cls-4" points="0 1080 0 922.13 157.87 922.13 0 1080"/>
                            <polygon className="cls-5" points="0 146.64 0 0 146.64 0 0 146.64"/>
                            <polygon className="cls-6"
                                     points="157.76 923.87 314.05 768.12 313.91 484.41 157.76 640.51 157.76 923.87"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div className="w right">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 593.46 1042.2">
                    <title>Rechts_</title>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <polygon className="cls-1" points="375.48 233.14 479.52 112.8 593.46 233.34 375.48 233.14"/>
                            <polygon className="cls-2" points="0 1042.2 132.4 909.8 264.8 1042.2 0 1042.2"/>
                            <polygon className="cls-3"
                                     points="434.93 532.79 434.93 386.14 581.57 386.14 434.93 532.79"/>
                            <polygon className="cls-4"
                                     points="588.54 1040.68 430.67 1040.68 430.67 882.81 588.54 1040.68"/>
                            <polygon className="cls-5"
                                     points="442.44 709.03 328.83 709.03 328.83 595.42 442.44 709.03"/>
                            <polygon className="cls-6" points="592.67 232.04 479.6 112.24 591.85 0 592.67 232.04"/>
                        </g>
                    </g>
                </svg>
            </div>
        </div>);
    }
}