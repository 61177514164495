import React, {Component} from 'react';
import {observer, inject} from "mobx-react";

export default inject('store')(observer(class LoopThreeOverlay extends Component {
    constructor(props){
        super(props);
        this.state = {
            showTime: Date.now()
        }
    }

    render(){
        // if(this.props.store.isSamsungGayAssBrowser) return null;

        const className = this.props.store.showingContinueButton ? 'messages-overlay show' : 'messages-overlay';
        const watchTime = Math.round((this.state.showTime - this.props.store.watchPhoneStartTime)/1000) - 6;// ten seconds remove from this
        // console.log('loop 3 watch time: ', watchTime, 'seconds');


        return (<div className={className}>
            <div className="center">
                <p>You just checked your phone for about {watchTime}&nbsp;seconds.</p>
                <button className="btn">See what you've missed</button>
            </div>
        </div>)
    }
}));